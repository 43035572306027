import request from './request'
import Tag, { Tags } from 'models/tag'

export async function getHomeTags(): Promise<Tag[]> {
  try {
    const res = {
      data: [
        { id: '64705b483656d2443939cd72', name: 'Werewolf', isHot: true },
        { id: '64705b483656d2443939cd70', name: 'Erotic', isHot: false },
        { id: '65782fb452eca159c9b6bd06', name: 'Rejected Mate', isHot: false },
        {
          id: '65783dc452eca159c9daaac6',
          name: 'Billionaire/CEO',
          isHot: true,
        },
        { id: '65782fb452eca159c9b6bea5', name: 'Mafia Leader', isHot: false },
        { id: '64758d78aa6caf33c2bd069b', name: 'Dragon', isHot: true },
        { id: '64705b483656d2443939cd75', name: 'Alpha', isHot: true },
        { id: '64705b493656d2443939cd92', name: 'Virgin', isHot: false },
        { id: '64705b487d5949c3fc445653', name: 'Fantasy', isHot: false },
        { id: '64705dba3d17715c17987ce9', name: 'Bad Boy', isHot: false },
        { id: '64705b486e1457b2a38dadbb', name: 'Forbidden', isHot: false },
        { id: '64705b486e1457b2a38dadc7', name: 'Revenge', isHot: false },
        { id: '64705b48a65f926f00824bca', name: 'BDSM', isHot: true },
        {
          id: '64705b483d17715c17985dfc',
          name: 'Enemies To Lovers',
          isHot: false,
        },
        { id: '64705b483656d2443939cd73', name: 'BXG', isHot: false },
        { id: '64705b483656d2443939cd6f', name: 'Pregnancy', isHot: false },
        { id: '64705b48555bab3337edb0d5', name: 'Possessive', isHot: false },
        { id: '649fbe8b30c23e8fdadf0445', name: 'Reverse Harem', isHot: false },
        { id: '64705b494603f6b8eb87c095', name: 'Shifter', isHot: false },
        { id: '64705b483d17715c17985dfd', name: 'Abuse', isHot: false },
        { id: '649fbe8b30c23e8fdadefcae', name: 'Fated Mate', isHot: false },
        {
          id: '6578760452eca159c99a206c',
          name: 'Supernature Earth',
          isHot: false,
        },
        { id: '6470686d5c66739dafeed3d1', name: 'Smut', isHot: false },
        { id: '64705b495c66739dafee7d68', name: 'LGBT+', isHot: false },
        {
          id: '64705b485c66739dafee7d57',
          name: 'Strong Female Lead',
          isHot: false,
        },
        { id: '64705dba3d17715c17987cea', name: 'Kidnap', isHot: false },
        { id: '64705b486e1457b2a38dadbc', name: 'Age Gap', isHot: false },
        {
          id: '65817cff52eca159c9195f9f',
          name: 'Contract Marriage/Arranged Marriage',
          isHot: false,
        },
        { id: '64705dba5c66739dafee9ae7', name: 'BXB', isHot: false },
        { id: '64705b48dd3d6d341cfd3916', name: 'Dominant', isHot: false },
        { id: '64705b49555bab3337edb0ec', name: 'Polyamory', isHot: false },
      ],
    }
    return res.data
  } catch (err) {
    if (err.response?.status !== 200) return []
    throw err
  }
}

export async function getTags({
  pageIndex = 1,
  pageSize = 500,
}: {
  pageIndex?: number
  pageSize?: number
}): Promise<Tags> {
  try {
    const res = await request(`/api/v1/bookcitypaidwebapp/book/taglist`, {
      params: {
        pageIndex,
        pageSize,
      },
    })
    return res?.data?.data || { total: 0, data: [] }
  } catch (err) {
    if (err.response?.status === 400) return null
    throw err
  }
}

export default {
  getTags,
  getHomeTags,
}
