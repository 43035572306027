import request from './request'
import Comment from 'models/comment'

export async function getComments({
  id,
  offset = 0,
  limit = 10,
}: {
  id: string
  offset?: number
  limit?: number
  sort?: number
  showRepliesNumber?: number
}): Promise<Comment> {
  try {
    const res = await request(`/api/v1/book/${id}/comments`, {
      params: {
        offset,
        limit,
      },
    })

    return res.data
  } catch (err) {
    if (err.response?.status === 400) return null
    throw err
  }
}

export default {
  getComments,
}
