import request from './request'

export async function search({
  keyword,
  pageIndex = 0,
  pageSize = 9,
}: {
  keyword: string
  pageIndex?: number
  pageSize?: number
}): Promise<any> {
  try {
    const res = await request(`/api/v1/bookcitypaidwebapp/search`, {
      params: {
        keyword,
        pageIndex,
        pageSize,
      },
    })
    return res?.data?.data || []
  } catch (err) {
    if (err.response?.status === 400) return []
    throw err
  }
}

export default {
  search,
}
